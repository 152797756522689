<template>
  <b-modal
    id="photocontrolModal"
    title="Назначить фотоконтроль"
    size="md"
    hide-footer
    no-enforce-focus
    :no-close-on-backdrop="true"
    @show="getData()"
    @close="closeModal()"
    @shown="detectStartTime"
    @hidden="detectEndTime"
  >
    <b-row>
      <b-col cols="12">
        <b-row>
          <b-col cols="12">
            <label for=""
              >Связка Водитель - Автомобиль:
              <span class="required">*</span></label
            >
            <v-select
              v-model="photo_control.performer_id"
              :reduce="(option) => option.driver_id"
              placeholder="Связка Водитель - Автомобиль"
              label="name"
              :options="options"
              class="select-size-md"
            >
            </v-select>
          </b-col>
        </b-row>
        <b-row class="mt-2">
          <b-col cols="6">
            <b-button style="width: 100%" variant="primary">
              Автомобиль
            </b-button>
          </b-col>
          <b-col cols="6">
            <b-button style="width: 100%" variant="primary">
              Водитель
            </b-button>
          </b-col>
        </b-row>
        <b-row class="mt-2">
          <b-col cols="12">
            <label for=""
              >Тип фотоконтроля: <span class="required">*</span></label
            >
            <v-select
              v-model="photo_control.type_photo_control_id"
              :reduce="(option) => option.id"
              placeholder="Тип фотоконтроля"
              label="alias_for_file_name"
              :options="photo_control_types"
              class="select-size-md"
            >
            </v-select>
          </b-col>
          <b-col cols="12" class="mt-2">
            <label for=""
              >Дата, c которой нужно загрузит фото:
              <span class="required">*</span></label
            >
            <b-input type="date" v-model="photo_control.start_date"></b-input>
          </b-col>
          <b-col cols="12" class="mt-2">
            <label for=""
              >Дата, до которой нужно загрузит фото:
              <span class="required">*</span></label
            >
            <b-input type="date" v-model="photo_control.end_date"></b-input>
          </b-col>
          <b-col cols="6" class="mt-2">
            <label for="">Статус</label>
            <b-input
              v-model="status"
              disabled
              style="color: black !important"
            />
          </b-col>
          <b-col class="mt-2" cols="12">
            <label for="">Комментарий</label>
            <b-textarea
              style="height: 120px"
              v-model="photo_control.description"
            >
            </b-textarea>
          </b-col>
        </b-row>
      </b-col>
    </b-row>
    <b-row class="mt-2 mb-1">
      <b-col class="d-flex justify-content-end">
        <b-button @click="addPhotocontrol()" variant="primary"
          >Назначить</b-button
        >
        <b-button class="ml-1 mr-1" @click="closeModal()" variant="danger"
          >Отмена</b-button
        >
      </b-col>
    </b-row>
    <b-modal
      hide-footer
      no-enforce-focus
      centered
      :no-close-on-backdrop="true"
      id="photocontrolInfoModal"
      @close="closeModal()"
    >
      <p>
        Вы не можете назначить фотоконтроль, так как данный автомобиль не
        прикреплен к водителю
      </p>
      <div class="d-flex justify-content-end">
        <b-button variant="primary" @click="closeModal()"> Ок </b-button>
      </div>
    </b-modal>
  </b-modal>
</template>

<script>
import vSelect from "vue-select";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";

export default {
  props: ["id"],
  components: {
    vSelect,
    ToastificationContent,
  },
  data() {
    return {
      formTime: {
        form_name: null,
        start_time: null,
        end_time: null,
      },
      status: "Назначен",
      car: [],
      photo_control_types: [],
      photo_control: {
        division_id: "",
        performer_id: "",
        type_photo_control_id: "",
        start_date: "",
        end_date: "",
        description: undefined,
      },
      options: [],
    };
  },
  methods: {
    detectEndTime() {
      this.formTime.end_time = this.$moment().format("YYYY-MM-DD HH:mm:ss");
      this.$http
        .post("time-form", this.formTime)
        .then((res) => {})
        .catch((err) => {
          console.log(err);
        });
    },
    detectStartTime() {
      this.formTime.form_name = "photocontrolCreateForm";
      this.formTime.start_time = this.$moment().format("YYYY-MM-DD HH:mm:ss");
    },
    getData() {
      this.$http
        .get(`cars/${this.id.id}/edit`)
        .then((res) => {
          this.car = res.data;
          if (!this.car.car_drivers) {
            this.$bvModal.show("photocontrolInfoModal");
          }
          this.car.car_drivers.forEach((el) => {
            let a =
              this.car.division +
              " " +
              "-" +
              " " +
              el.first_name +
              " " +
              el.last_name +
              " " +
              "-" +
              " " +
              this.car.model +
              " " +
              this.car.car_number;
            this.options.push({ name: a, driver_id: el.driver_id });
          });
        })
        .catch((err) => {
          console.log(err);
        });
      this.$http.get("photo-control/type").then((res) => {
        this.photo_control_types = res.data;
      });
    },
    addPhotocontrol() {
      this.photo_control.description = this.photo_control.description
        ? this.photo_control.description
        : undefined;
      this.$http
        .post("photo-control/performer-assign", this.photo_control)
        .then(() => {
          this.$toast({
            component: ToastificationContent,
            position: "top-right",
            props: {
              title: "Успех!",
              icon: "CheckIcon",
              variant: "success",
              text: `Фотоконтроль назначен`,
            },
          });
          this.closeModal();
        })
        .catch((err) => {
          console.log(err);
        });
    },
    closeModal() {
      this.$bvModal.hide("photocontrolInfoModal");
      this.status = "Назначен";
      this.car = [];
      this.photo_control_types = [];
      this.photo_control = {
        performer_id: "",
        type_photo_control_id: "",
        start_date: new Date().toISOString().split("T")[0],
        end_date: "",
        description: undefined,
      };
      this.options = [];
      this.$bvModal.hide("photocontrolModal");
    },
  },
};
</script>

<style scoped>
.required {
  color: red;
}
</style>
